<template>
    <div class="container" v-loading="loading"  element-loading-text="加載内容中"
    element-loading-spinner="el-icon-loading"
    element-loading-background="rgba(0, 0, 0, 0.8)">
        <div class="article">
            <img v-if="article.icon"
                :src="fileUrl(article.icon)"
                alt=""
                class="poster"
            />
            <p class="p_title">{{article.title}}</p>
            <p class="p_time">{{article.createDatetime}}</p>
            <div class="content" v-html="article.content" style="white-space: pre-wrap;"></div>
            <primary-button
                class="back-button"
                radius="lg"
                @click="$router.go(-1)"
            >
                返回
            </primary-button>
        </div>
        <div class="share-container">
            <div class="h1">分享</div>
            <div class="h2">讓更多人一齊打氣！</div>
            <div class="note">打氣次數：{{article.pumpeTimes}}</div>
            <div class="share-links">
<!--                <img src="../assets/img/Group1.png" alt="" class="share-link" />-->
<!--                <img src="../assets/img/Group2.png" alt="" class="share-link" />-->
<!--                <img src="../assets/img/Group3.png" alt="" class="share-link" />-->
<!--                <img src="../assets/img/Group4.png" alt="" class="share-link" />-->
                <facebook-button class="share-button--painted" :url="url" :description="description" @click="pumpeTime" />
                <whats-app-button class="share-button--painted" :url="url" @click="pumpeTime" />
                <weibo-button class="share-button--painted" :url="url" :description="description" @click="pumpeTime" />
                <twitter-button class="share-button--painted" :url="url" :description="description" @click="pumpeTime" />
            </div>
            <div class="comment-container"></div>
        </div>
    </div>
</template>

<script>
import api from "@/api";
import FacebookButton from "vue-share-buttons/src/components/FacebookButton";
import WhatsAppButton from "vue-share-buttons/src/components/WhatsAppButton";
import WeiboButton from "vue-share-buttons/src/components/WeiboButton";
import TwitterButton from "vue-share-buttons/src/components/TwitterButton";

export default {
    components: {
        FacebookButton,
        WhatsAppButton,
        WeiboButton,
        TwitterButton
    },
    data() {
        return {
            article: {},
            loading: false,
            url: undefined,
            description: undefined
        }
    },
    created() {
        this.url =window.location.href
    },
    mounted() {
        this.loading = true;
        let id =this.$route.params.id
        api.article.getArticle(id)
            .then((res) => {
                let date = res.data.createDatetime.split(" ")[0];
                let time = date.split("-");
                res.data.createDatetime = time[0]+'年'+time[1]+'月'+time[2]+'日';

                this.article = res.data;
                this.description = "籌款媒體報導-" +this.article.title;
                this.loading = false;
            }).catch((error) => {
                this.loading = false;
                console.log(error);
            });
    },
    methods: {
        fileUrl(file) {
            return process.env.VUE_APP_SERVER +file;
        },
        pumpeTime() {
            api.article.pumpeTime(this.article.id);
        }
    }
};
</script>

<style lang="scss" scoped>
.p_time{
    margin-top:10px;
    width: 100%;
    text-align: left;
}
.p_title{
    font-weight: bold;
    font-size: 30px;
    width: 100%;
    text-align: left;
    margin-top:30px;
}
.container {
    padding-top: 80px;
    min-height: calc(100vh - 48px);
    @include fr-c-s;
    flex-wrap: wrap;
    .article {
        flex-grow: 1;
        flex-basis: 350px;
        min-width: 350px;
    }
    .share-container {
        flex-grow: 1;
        flex-basis: 200px;
        min-width: 200px;
        max-width: 500px;
    }
}
.article {
    align-self: stretch;
    padding: 16px;
    @include fc-s-c;
    img {
        width: 60%;
        height:40%;
    }
    .content {
        width: 100%;
        flex-grow: 1;
        margin-top: 50px;
    }
    .back-button {
        width: 80vw;
        max-width: 300px;
        margin: 16px 0;
    }
}
.share-container {
    margin: 16px;
    padding: 16px;
    border: 1px solid $green-500;
    & > div {
        margin-bottom: 16px;
    }
    .h1 {
        font-size: 40px;
        font-weight: 600;
        color: $green-500;
    }
    .h2 {
        font-size: 28px;
        font-weight: 600;
        color: $green-500;
    }
    .note {
        font-size: 18px;
        color: $gray-500;
    }
    .share-links {
        @include fr-s-c;
        .share-link {
            width: 20%;
            height: 100px;
            border-radius: 50%;
            background-color: green;
        }
    }
}
</style>